import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
// import Staff from "../components/staff";
import marked from 'marked'

class AboutUs extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [pageData] = get(this, 'props.data.allContentfulAboutUs.edges')
    const [banner] = get(this, 'props.data.allContentfulBanner.edges')
    // const staffMembers = this.props.data.allContentfulStaffMember.edges;

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet
            title={`About us - ${siteTitle}`}
            meta={[
              {
                name: 'description',
                content: 'About us - Aoga Amata Newtown',
              },
              { name: 'keywords', content: 'daycare, wellington, newtown' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Hero data={banner.node} />
          <div className="wrapper">
            <div
              className="section-content"
              dangerouslySetInnerHTML={{
                __html: marked(pageData.node.content.content),
              }}
            ></div>
            {/* <div className="section-content">
              <h3>Our staff</h3>
              {staffMembers &&
                staffMembers.map((member, index) => {
                  return <Staff key={`member${index}`} data={member.node} />;
                })}
            </div> */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutUs

export const pageQuery = graphql`
  query AboutUsQuery {
    allContentfulBanner(
      filter: { contentful_id: { eq: "6c0eCejIU2kYRiw45Lg16A" } }
    ) {
      edges {
        node {
          title
          heroImage: image {
            fluid(
              maxHeight: 500
              resizingBehavior: PAD
              background: "rgb:FFFFFF"
            ) {
              src
              srcSet
              base64
            }
          }
        }
      }
    }
    allContentfulAboutUs {
      edges {
        node {
          title
          content {
            content
          }
        }
      }
    }
    allContentfulStaffMember(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          fullName
          content {
            content
          }
          picture {
            fluid(
              maxHeight: 500
              resizingBehavior: PAD
              background: "rgb:FFFFFF"
            ) {
              src
              srcSet
              base64
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
